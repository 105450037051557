

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 0.75vw;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: 1vw 5.5vw;
    margin: 0;
    background-color: #1F2937;
    border-bottom: 0.2vw solid #3882F6;
    font-size: 1.19vw;
}

.header-item {
    flex: 1;
}

.color, .heroText {
    color: #F9FAF8;
}

body {
    margin: 0;
    font-family: roboto, arial;
}

.hero {
    background-color: #1F2937;
    padding: 5.5vw;
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
}

.heroGroup {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /*align-items: center;*/
    gap:3vw;
}

.heroText {
    flex: 1;
}

.heroSubText {
    font-size: 1.15vw;
    margin-top: 0;
    margin-bottom: 0.2vw;
}

.heroMainText {
    font-size: 1.94vw;
    font-weight: bold;
    margin-bottom: 1vw;
}

.input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.inputField { 
    width:10vw;
    height:1vw;
    font-size:1vw;

}
/*hero input herosubtext color inputfield*/

.inputField2 { 
    width:6vw;
    height:1vw;
    font-size:1vw;

}

.inputText {
    font-size: 1.15vw;
    margin-top: 0.7vw;
    margin-bottom: 0.2vw;
}

.inputSubText {
    font-size: 0.945vw;
    margin-top: 0;
    margin-bottom: 0.2vw;
}


.button {
    text-decoration: none;
    background-color: #3882F6;
    font-weight: bold;
    padding: 0.3vw 1.1vw;
    border-radius: 1vw;
    display: inline-block;
    margin: 1.4vw 0;
    font-size: 1.17vw;
    border: none;
}

.loading {
    margin: auto;
    border: 0.35vw solid #EAF0F6;
    border-radius: 2.1vw;
    border-top: 0.35vw solid #3882F6;
    width: 2vw;
    height: 2vw;
    animation: spinner 1s linear infinite;
}
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.heroLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unregister {
    padding: 0.6vw;
    border: 0.24vw solid #3882F6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:50vw;
    align-items: center;
    gap: 1vw;
}

.unregisterItem {
    /**/
}


.head3 {
    flex:0.3;
}
/*heroGroup, unregister, font size cua moi cai, header, margin top bottom cua input text*/

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
}
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .heroGroup {
        flex-direction: column;
    }
    
    .header {
        height: 1.5vw;
        font-size: 2.38vw;
    }
    .head3 {
        flex:0.5;
    }

    .heroSubText {
        font-size: 1.68vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }
    
    .heroMainText {
        font-size: 2.5vw;
        font-weight: bold;
        margin-bottom: 1vw;
    }

    .inputText {
        font-size: 1.68vw;
        margin-top: 0.5vw;
        margin-bottom: 0.0vw;
    }
    
    .inputSubText {
        font-size: 1.4vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }

    .inputField { 
        width:15vw;
        height:1.5vw;
        font-size:1.32vw;
    
    }
    
    .inputField2 { 
        width:7vw;
        height:1.62vw;
        font-size:1.32vw;
    }

        
    .button {
        text-decoration: none;
        background-color: #3882F6;
        font-weight: bold;
        padding: 0.5vw 1.1vw;
        border-radius: 1vw;
        display: inline-block;
        margin: 1.4vw 0;
        font-size: 1.24vw;
        border: none;
    }

    .loading {
        margin: auto;
        border: 0.6vw solid #EAF0F6;
        border-radius: 4vw;
        border-top: 0.6vw solid #3882F6;
        width: 4vw;
        height: 4vw;
        animation: spinner 1s linear infinite;
    }
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .heroLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .unregister {
        padding: 0.75vw;
        border: 0.3vw solid #3882F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:55vw;
        align-items: center;
        gap: 1vw;
    }
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { 
    .heroGroup {
        flex-direction: column;
    }
    
    .header {
        height: 1.5vw;
        font-size: 2.38vw;
    }
    .head3 {
        flex:0.5;
    }

    .heroSubText {
        font-size: 1.68vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }
    
    .heroMainText {
        font-size: 2.5vw;
        font-weight: bold;
        margin-bottom: 1vw;
    }

    .inputText {
        font-size: 1.68vw;
        margin-top: 0.5vw;
        margin-bottom: 0.0vw;
    }
    
    .inputSubText {
        font-size: 1.4vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }

    .inputField { 
        width:15vw;
        height:1.5vw;
        font-size:1.32vw;
    
    }
    
    .inputField2 { 
        width:7vw;
        height:1.62vw;
        font-size:1.32vw;
    }

        
    .button {
        text-decoration: none;
        background-color: #3882F6;
        font-weight: bold;
        padding: 0.5vw 1.1vw;
        border-radius: 1vw;
        display: inline-block;
        margin: 1.4vw 0;
        font-size: 1.24vw;
        border: none;
    }

    .loading {
        margin: auto;
        border: 0.44vw solid #EAF0F6;
        border-radius: 2.1vw;
        border-top: 0.44vw solid #3882F6;
        width: 3vw;
        height: 3vw;
        animation: spinner 1s linear infinite;
    }
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .heroLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .unregister {
        padding: 0.75vw;
        border: 0.3vw solid #3882F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:55vw;
        align-items: center;
        gap: 1vw;
    }
}
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) { 
    .heroGroup {
        flex-direction: column;
    }
    
    .hero {
        padding:7vw;
    }

    .header {
        height: 4vw;
        font-size: 3.2vw;
    }
    .head3 {
        flex: 0.75;
    }

    .heroSubText {
        font-size: 2.6vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }
    
    .heroMainText {
        font-size: 3.9vw;
        font-weight: bold;
        margin-bottom: 1vw;
    }

    .inputText {
        font-size: 2.6vw;
        margin-top: 0.5vw;
        margin-bottom: 0.0vw;
    }
    
    .inputSubText {
        font-size: 2vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }

    .inputField {
        width:40vw;
        height: 12px;
        font-size:2.7vw;
    
    }
    
    .inputField2 { 
        width:14vw;
        height: 12px;
        font-size:2.7vw;
    }

    .button {
        text-decoration: none;
        background-color: #3882F6;
        font-weight: bold;
        padding: 0.6vw 1.25vw;
        border-radius: 2vw;
        display: inline-block;
        margin: 1.4vw 0;
        font-size: 2.8vw;
        border: none;
    }

    .loading {
        margin: auto;
        border: 1vw solid #EAF0F6;
        border-radius: 6vw;
        border-top: 1vw solid #3882F6;
        width: 6vw;
        height: 6vw;
        animation: spinner 1s linear infinite;
    }
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .heroLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .unregister {
        padding: 1vw;
        border: 0.3vw solid #3882F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 73vw;
        align-items: center;
        gap: 1vw;
    }

    .unretext {
        font-size: 2.6vw;
    }
}
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .heroGroup {
        flex-direction: column;
    }
    
    .hero {
        padding:10vw;
    }

    .header {
        height: 5vw;
        font-size: 3.5vw;
    }
    .head3 {
        flex: 0.75;
    }

    .heroSubText {
        font-size: 3.2vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }
    
    .heroMainText {
        font-size: 4.3vw;
        font-weight: bold;
        margin-bottom: 1vw;
    }

    .inputText {
        font-size: 3.1vw;
        margin-top: 0.5vw;
        margin-bottom: 0.0vw;
    }
    
    .inputSubText {
        font-size: 2.6vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }

    .inputField {
        width:45vw;
        height: 12px;
        font-size:12.9px;
    
    }
    
    .inputField2 { 
        width:14vw;
        height: 12px;
        font-size:12.9px;
    }

    .button {
        text-decoration: none;
        background-color: #3882F6;
        font-weight: bold;
        padding: 1vw 1.25vw;
        border-radius: 2.3vw;
        display: inline-block;
        margin: 2vw 0 0 0;
        font-size: 2.8vw;
        border: none;
    }

    .loading {
        margin: auto;
        border: 1vw solid #EAF0F6;
        border-radius: 6vw;
        border-top: 1vw solid #3882F6;
        width: 6vw;
        height: 6vw;
        animation: spinner 1s linear infinite;
    }
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .heroLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .unregister {
        padding: 1vw;
        border: 0.3vw solid #3882F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85vw;
        align-items: center;
        gap: 2vw;
    }

    .unretext {
        font-size: 3vw;
    }
    
  }

  @media (max-width: 319px) {
    .heroGroup {
        flex-direction: column;
    }
    
    .hero {
        padding:10vw;
    }

    .header {
        height: 5vw;
        font-size: 3.5vw;
    }
    .head3 {
        flex: 0.75;
    }

    .heroSubText {
        font-size: 4.6vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }
    
    .heroMainText {
        font-size: 6vw;
        font-weight: bold;
        margin-bottom: 1vw;
    }

    .inputText {
        font-size: 4.6vw;
        margin-top: 0.5vw;
        margin-bottom: 0.0vw;
    }
    
    .inputSubText {
        font-size: 4vw;
        margin-top: 0;
        margin-bottom: 0.2vw;
    }

    .inputField {
        width:45vw;
        height: 12px;
        font-size:12.9px;
    
    }
    
    .inputField2 { 
        width:14vw;
        height: 12px;
        font-size:12.9px;
    }

    .button {
        text-decoration: none;
        background-color: #3882F6;
        font-weight: bold;
        padding: 1vw 1.25vw;
        border-radius: 2.3vw;
        display: inline-block;
        margin: 2vw 0 0 0;
        font-size: 4.6vw;
        border: none;
    }

    .loading {
        margin: auto;
        border: 1vw solid #EAF0F6;
        border-radius: 6vw;
        border-top: 1vw solid #3882F6;
        width: 6vw;
        height: 6vw;
        animation: spinner 1s linear infinite;
    }
    @keyframes spinner {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .heroLoading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .unregister {
        padding: 1vw;
        border: 0.3vw solid #3882F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 85vw;
        align-items: center;
        gap: 2vw;
    }

    .unretext {
        font-size: 4.6vw;
    }
 }